html {
  /*the vw of 100vw split by 320pt; ie pt/vw*/
  font-size: 0.3125vw;
  -webkit-overflow-scrolling: touch;
}

@import url('https://fonts.googleapis.com/css?family=Noto+Sans');

@media (min-width: 420px) {
  html {
    font-size: 13px;
  }
}
@media (min-width: 768px) {
  html {
    font-size: 10px;
  }
}
@media (min-width: 1014px) {
  html {
    font-size: 11.5px;
  }
}

/*
375px = iPhone 7
412px = S8+
414px = iPhone 7+
768px = iPad mini
*/
@media (min-width: 320px) {
  /* smartphones, iPhone, portrait 480x320 phones */
    html {
      font-size: 12px;
    }
}
@media (min-width: 481px) {
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}
@media (min-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
}
@media (min-width: 961px) {
  /* tabletMin, landscape iPad, lo-res laptops ands desktops */
}
@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}
@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
}

@media print {
  html {
    font-size: 10.5px;
  }
}
@media (max-width: 767px) {
  button,
  input:focus {
    outline: none;
  }
}
body {
  display: flex;
  justify-content: center;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}
body,
button,
input,
textarea,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  font-family: 'Noto Sans', sans-serif;
  font-weight: normal;
}
body,
button,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  user-select: none;
}
button {
  background: none;
  border: none;
}
#root {
  display: flex;
  justify-content: center;
  width: 100%;
}
@font-face {
  font-family: "Noto Sans";
  font-display: fallback;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  cursor: default;
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 0.8rem;
}
/* Track */
::-webkit-scrollbar-track {
  background: rgba(158, 158, 158, 1);
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #007d40;
}
